import _ from 'lodash'

const filters = {
  firestorePath: 'data/{userId}/filters',
  firestoreRefType: 'collection',
  moduleName: 'filters',
  statePropName: 'filters',
  namespaced: true,
  state: {},
  getters: {
    getFilters: state => _.values(JSON.parse(JSON.stringify(state.filters))),
    getFiltersByModelId: (state, getters) => modelId => _.filter(getters.getFilters, f => f.modelId === modelId),
    getGroupedFiltersByModelId: (state, getters) => modelId => {
      if (modelId) {
        return _.groupBy(getters.getFiltersByModelId(modelId), 'fieldId')
      }

      return []
    },
  },
  mutations: {},
  actions: {
    CREATE_FILTER: ({ dispatch }, {
      fieldId, fieldName, fieldValue, modelId,
    }) => dispatch('insert', {
      fieldId,
      fieldName,
      fieldValue,
      modelId,
    }).then(id => id),
  },
}

export default filters
