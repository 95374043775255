import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

const baseFields = {
  firstName: '',
  lastName: '',
}

const contacts = {
  firestorePath: 'data/{userId}/contacts',
  firestoreRefType: 'collection',
  moduleName: 'contacts',
  statePropName: 'contacts',
  namespaced: true,
  state: {
    selectedContact: '',
  },
  getters: {
    getContacts: state => _.chain(JSON.parse(JSON.stringify(state.contacts)))
      .values()
      .value(),
    getContactById: state => id => state.contacts[id],
    getSelectedContact: state => state.selectedContact,
    getFilteredContacts: (state, getters) => filters => {
      const arrayContact = getters.getContacts
      const groupedByFilters = _.groupBy(filters, 'fieldId')
      const values = _.filter(arrayContact, contact => _.every(groupedByFilters, filterGroup => _.some(
        filterGroup,
        o => contact.fields[o.fieldId] !== undefined && _.isEqual(contact.fields[o.fieldId], o.fieldValue),
      )))

      return values
    },
    getCurrentFieldValuesById: (state, getters) => fieldId => {
      if (fieldId == null) return []
      const arrayContact = getters.getContacts
      const values = _.chain(arrayContact)
        .map('fields')
        .map(fieldId)
        .filter(n => n !== '' && n !== undefined && n !== null)
        .uniqWith(_.isEqual)
        .value()

      return values
    },
    getAllFields: (state, getters) => {
      const arrayContact = getters.getContacts
      const fields = _.chain(arrayContact)
        .map('fields')
        .map(_.values)
        .flatten()
        .uniqBy('id')
        .filter(n => n !== '')
        .value()

      return fields
    },
    getAllFieldsNames: (state, getters) => {
      const arrayContact = getters.getContacts
      const values = _.chain(arrayContact).map('fields').map(_.values).flatten()
        .map('name')
        .uniq()
        .value()

      return values
    },
    getAllFieldsIds: (state, getters) => {
      const arrayContact = getters.getContacts

      // Get all fields ids from contacts
      const fieldsIds = _.chain(arrayContact).map('fields').map(_.keys).flatten()
        .uniq()
        .value()

      return fieldsIds
    },
    getFields: (state, getters, rootState, rootGetters) => contact => {
      const model = rootGetters['models/getModelById'](contact.modelId)
      if (model == null) return []
      const modelFields = _.orderBy(_.values(model.fields), ['orderKey'])

      return modelFields.map(field => ({
        ...field,
        value: contact.fields[field.id] || null,
      }))
    },

    // Find domain in company field and email field
    // Return null if no domain found
    getCompanyDomain: (state, getters) => contact => {
      // Find domain in company field if it exists
      const companyField = getters.getFirstFieldByType(contact, 'company')
      if (companyField && companyField.value && companyField.value.domain) {
        return companyField.value.domain
      }

      // Find domain in email field if it exists
      const emailField = getters.getFirstFieldByType(contact, 'email')
      if (emailField && emailField.value && emailField.value.includes('@')) {
        return emailField.value.split('@')[1]
      }

      return null
    },

    // Get names fields from all contacts (First name / Last name)
    // Currently based on the property: required (TODO: improve)
    getNamesFields: (state, getters) => contact => getters.getFields(contact).filter(field => field.required),
    getOtherFields: (state, getters) => contact => getters.getFields(contact).filter(field => !field.required),
    getFirstFieldByType: (state, getters) => (contact, fieldType) => getters.getFields(contact).find(field => field.type === fieldType),
    getNotesByContactId: (state, getters) => id => getters.getContactById(id).notes,

    // Tasks
    getTasksByContactId: (state, getters) => contactId => {
      const { tasks } = getters.getContactById(contactId)

      return _.chain(tasks)
        .map(task => ({ ...task, contactId })) // Add contactId to task
        .orderBy('reminderDate')
        .value()
    },
    getAllTasks: (state, getters) => {
      const tasks = getters.getContacts.map(contact => getters.getTasksByContactId(contact.id))

      return _.chain(tasks).flatten().orderBy('reminderDate').value()
    },
  },
  mutations: {
    setSelectedContact(state, contactId) {
      state.selectedContact = contactId
    },
  },
  actions: {
    CREATE_CONTACT: ({ dispatch }, {
      id, modelId, fields, notes,
    }) => dispatch('insert', {
      id,
      modelId,
      fields: fields || baseFields,
      notes: notes || '',
      tasks: {},
    }),

    // Create a task for a contact and add it to the contact
    CREATE_TASK: ({ dispatch }, { contactId, label, reminderDate }) => {
      const taskId = uuidv4()
      dispatch('patch', {
        id: contactId,
        tasks: {
          [taskId]: {
            id: taskId,
            label,
            reminderDate,
            done: false,
          },
        },
      })
    },

    // Update a task
    UPDATE_TASK: ({ dispatch }, {
      contactId, taskId, label, reminderDate, done,
    }) => {
      dispatch('patch', {
        id: contactId,
        tasks: {
          [taskId]: {
            label,
            reminderDate,
            done,
          },
        },
      })
    },

    // Delete a task
    DELETE_TASK: ({ dispatch }, { contactId, taskId }) => {
      dispatch('delete', `${contactId}.tasks.${taskId}`)
    },
    SELECT_CONTACT: ({ commit }, contactId) => {
      commit('setSelectedContact', contactId)
    },
    UNSELECT_CONTACT: ({ commit }) => {
      commit('setSelectedContact', null)
    },
  },
}

export default contacts
