<template>
  <div>
    <div class="pa-0 py-3">
      <!-- Add task input -->
      <v-textarea
        v-model="newTaskLabel"
        class="pa-0"
        placeholder="Ajouter une nouvelle tâche"
        hide-details
        :rows="1"
        no-resize
        auto-grow
      ></v-textarea>
    </div>
    <!-- Action buttons -->
    <!-- Appear when the user starts typing -->
    <div>
      <Transition
        name="fade"
      >
        <div
          v-if="newTaskLabel"
          class="mt-1 pa-0"
        >
          <v-menu
            v-model="menuDatePicker"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                type="submit"
                color="primary"
                outlined
                x-small
                width="100%"
                class="mt-1"
                v-bind="attrs"
                v-on="on"
              >
                Prévu pour {{ relativeSelectedDate }} <v-icon
                  class="ml-1"
                  small
                >
                  {{ icons.mdiUpdate }}
                </v-icon>
              </v-btn>
            </template>
            <v-date-picker
              v-model="newTaskDate"

              :value="newTaskDate"
              color="primary"
              no-title
              locale="fr-fr"
            ></v-date-picker>
          </v-menu>
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            x-small
            width="100%"
            class="mb-2 mt-1"
            @click="submitNewTask"
          >
            Ajouter la tâche
          </v-btn>
        </div>
      </Transition>
    </div>
  </div>
</template>
<script>
import { mdiPlus, mdiUpdate } from '@mdi/js'
import { computed, defineComponent, ref } from '@vue/composition-api'
import moment from 'moment'
import humanReadableDateFromNow from '@/utils/date'
import store from '@/store'

export default defineComponent({
  props: {
    contactId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // Default date is tomorrow
    const defaultDate = moment().add(1, 'day').format('YYYY-MM-DD')
    const newTaskLabel = ref('')
    const newTaskDate = ref(defaultDate)
    const menuDatePicker = ref(false)
    const submitNewTask = () => {
      store.dispatch('contacts/CREATE_TASK', {
        contactId: props.contactId,
        label: newTaskLabel.value,
        reminderDate: newTaskDate.value,
      })
      newTaskLabel.value = ''
    }

    const relativeSelectedDate = computed(() => humanReadableDateFromNow(newTaskDate.value))

    return {
      newTaskLabel,
      newTaskDate,
      relativeSelectedDate,
      menuDatePicker,
      submitNewTask,
      icons: { mdiPlus, mdiUpdate },
    }
  },
})
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
