<template>
  <v-card elevation="0">
    <v-select
      v-model="selectedDate"
      class="title-select mb-2"
      flat
      solo
      :items="possibleDates"
      item-text="text"
      label="Date"
      hide-details
      return-object
    >
      <template v-slot:item="{ item }">
        <div :style="{ color: item.color }">
          {{ item.text }} <i>({{ filterTasksByInterval(tasks, item.interval).length }})</i>
        </div>
      </template>
    </v-select>

    <div v-if="filteredTasksBySelectedDate.length">
      <span v-if="taskRemainingCount == 0">Toutes vos tâches sont terminées 🎉</span>
      <span v-else>{{ taskRemainingCount }} tâche(s) restante(s)</span>
      <v-progress-linear
        v-model="taskFinishedPercent"
        :buffer-value="100"
        class="mb-2"
      ></v-progress-linear>
    </div>
    <div v-else>
      <span>Aucune tâche.</span>
    </div>

    <AddTaskForm
      v-if="contactId"
      :contact-id="contactId"
    />
    <div class="tasks-container">
      <Task
        v-for="task in filteredTasksBySelectedDate"
        :key="task.id"
        :value="task"
        class="mb-2"
        :display-contact="!contactId"
        @delete="deleteTask"
        @change="saveTask"
      />
    </div>
  </v-card>
</template>

<script>
import { mdiDelete } from '@mdi/js'
import { computed, defineComponent, ref } from '@vue/composition-api'
import AddTaskForm from '@/components/AddTaskForm.vue'
import Task from '@/components/Task.vue'
import store from '@/store'
import filterTasksByInterval from '@/utils/tasks'

export default defineComponent({
  components: {
    AddTaskForm,
    Task,
  },
  props: {
    contactId: {
      type: String,
      required: false,
    },
    defaultInterval: {
      type: String,
      required: false,
      default: 'today',
    },
  },
  setup(props) {
    const tasks = computed(() => {
      if (props.contactId) {
        return store.getters['contacts/getTasksByContactId'](props.contactId)
      }

      return store.getters['contacts/getAllTasks']
    })

    const possibleDates = [
      { text: 'Tâches terminées', interval: 'done', color: '#e5e5e5' },
      { text: 'Tâches en retard', interval: 'late', color: 'red' },
      { text: 'Tâches de la journée', interval: 'today' },
      { text: 'Tâches de la semaine', interval: 'week' },
      { text: 'Toutes les tâches à venir', interval: 'all' },
    ]
    const selectedDate = ref(possibleDates.find(date => date.interval === props.defaultInterval))
    const filteredTasksBySelectedDate = computed(() => filterTasksByInterval(tasks.value, selectedDate.value.interval))
    const taskFinishedCount = computed(() => filteredTasksBySelectedDate.value.filter(task => task.done).length)
    const taskFinishedPercent = computed(
      () => (taskFinishedCount.value / filteredTasksBySelectedDate.value.length) * 100,
    )
    const taskRemainingCount = computed(() => filteredTasksBySelectedDate.value.filter(task => !task.done).length)
    const newTaskLabel = ref('')
    const newTaskDate = ref('')

    const submitNewTask = () => {
      store.dispatch('contacts/CREATE_TASK', {
        contactId: props.contactId,
        label: newTaskLabel.value,
        reminderDate: newTaskDate.value,
      })
      newTaskLabel.value = ''
    }

    const deleteTask = task => {
      store.dispatch('contacts/DELETE_TASK', {
        contactId: task.contactId,
        taskId: task.id,
      })
    }

    const saveTask = task => {
      store.dispatch('contacts/UPDATE_TASK', {
        contactId: task.contactId,
        taskId: task.id,
        label: task.label,
        reminderDate: task.reminderDate,
        done: task.done,
      })
    }

    return {
      possibleDates,
      selectedDate,
      tasks,
      filterTasksByInterval,
      filteredTasksBySelectedDate,
      newTaskLabel,
      newTaskDate,
      taskRemainingCount,
      taskFinishedCount,
      taskFinishedPercent,
      submitNewTask,
      deleteTask,
      saveTask,
      icons: {
        mdiDelete,
      },
    }
  },
})
</script>
<style scoped>
.v-input__slot {
  padding: 0 !important;
  min-height: 100% !important;
}
.title-select {
  padding: 0 !important;
  font-size: 22px;
  font-weight: 900;
}
.tasks-container {
  max-height: 400px;
  overflow-y: auto;
}
</style>
<style>
.v-select__selection.v-select__selection--comma {
  overflow: unset;
}
</style>
