const moment = require('moment')

const filterTasksByInterval = (tasks, interval) => {
  const now = moment()
  const endOfWeek = now.clone().endOf('week')

  return tasks.filter(task => {
    const taskDate = moment(task.reminderDate, 'YYYY-MM-DD')
    if (interval === 'today') {
      return taskDate.isSame(now, 'day')
    }
    if (interval === 'week') {
      return taskDate.isBetween(now, endOfWeek) || taskDate.isSame(now, 'day')
    }
    if (interval === 'all') {
      return taskDate.isAfter(now) || taskDate.isSame(now, 'day')
    }
    if (interval === 'late') {
      return taskDate.isBefore(now) && !taskDate.isSame(now, 'day') && !task.done
    }
    if (interval === 'done') {
      return task.done
    }

    return true
  })
}

export default filterTasksByInterval
