import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import firebase from 'firebase/compat/app'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexEasyFirestore from 'vuex-easy-firestore'
import app from './app'
import contacts from './contacts'
import filters from './filters'
import models from './models'
import sorts from './sorts'
import user from './user'

const vuexEasyFirestoreModules = [contacts, models, filters, sorts, user]

const easyFirestore = VuexEasyFirestore(vuexEasyFirestoreModules, {
  logging: process.env.NODE_ENV === 'development',
  FirebaseDependency: firebase,
})

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [easyFirestore],
  state: {},
  mutations: {},
  actions: {
    initializeDBChannels: ({ dispatch }) => {
      console.debug('Initializing DB Channels')
      vuexEasyFirestoreModules.forEach(module => dispatch(`${module.moduleName}/openDBChannel`, null))
    },
    closeDBChannels: ({ dispatch }) => {
      console.debug('Closing DB Channels')
      vuexEasyFirestoreModules.forEach(module => dispatch(`${module.moduleName}/closeDBChannel`, { clearModule: true }))
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },
})
