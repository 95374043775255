<template>
  <div
    class="d-flex"
  >
    <div
      v-if="displayAvatar"
      class="v-avatar-group"
    >
      <!-- First Name / Last Name | Avatar Text -->
      <v-avatar
        v-if="avatarText"
        :color="'primary'"
        :class="'v-avatar-light-bg primary--text'"
        size="32"
      >
        <span>{{ avatarText }}</span>
      </v-avatar>
      <!-- Company | Avatar Logo -->
      <v-avatar
        v-if="companyDomain && !isImageNotFound"
        :color="'primary'"
        :class="'v-avatar-light-bg primary--text'"
        size="32"
      >
        <span><v-img
          width="32"
          style="margin-top: 3px"
          :src="`https://logo.clearbit.com/${companyDomain}`"
          @error="onImageError"
        ></v-img></span>
      </v-avatar>
    </div>
    <div class="d-flex flex-column ml-2">
      <div
        v-for="(field, index) in fieldsToDisplay"
        :key="field.text"
        :class="`text-truncate ${field.classes} ${clickable ? 'link' : ''}`"
        @click="clickable ? handleFieldClick(field.text) : null"
      >
        <span v-if="index < fieldMax">{{ field.text }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  computed, defineComponent, ref, watch,
} from '@vue/composition-api'
import store from '@/store'

export default defineComponent({
  props: {
    contact: {
      type: Object,
      required: true,
    },
    displayAvatar: {
      type: Boolean,
      default: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    fieldMax: {
      type: Number,
      default: 2,
    },
  },
  setup(props) {
    const companyDomain = computed(() => store.getters['contacts/getCompanyDomain'](props.contact))
    const isImageNotFound = ref(false)
    const onImageError = () => {
      isImageNotFound.value = true
    }
    const firstName = computed(() => props.contact.fields.firstName || '')
    const lastName = computed(() => props.contact.fields.lastName || '')
    const fullName = computed(() => `${firstName.value} ${lastName.value}`.trim())
    const avatarText = computed(
      () => `${firstName.value ? firstName.value.charAt(0) : ''}${lastName.value ? lastName.value.charAt(0) : ''}`,
    )

    const fieldsToDisplay = computed(() => {
      const fields = []
      if (fullName.value) {
        fields.push({
          text: fullName.value,
        })
      }
      const emailField = store.getters['contacts/getFirstFieldByType'](props.contact, 'email')
      if (emailField && emailField.value) {
        fields.push({
          text: emailField.value,
        })
      }
      const companyField = store.getters['contacts/getFirstFieldByType'](props.contact, 'company')
      if (companyField && companyField.value) {
        fields.push({
          text: companyField.value.name,
        })
      }
      if (!fields.length) {
        fields.push({
          text: 'Nouveau contact',
        })
      } else {
        fields[0].classes = 'd-block font-weight-semibold text--primary text-truncate'
      }

      return fields.splice(0, 2)
    })
    const handleFieldClick = () => {
      store.dispatch('contacts/SELECT_CONTACT', props.contact.id)
    }

    watch(companyDomain, () => {
      isImageNotFound.value = false
    })

    return {
      avatarText,
      companyDomain,
      fieldsToDisplay,
      onImageError,
      isImageNotFound,
      handleFieldClick,
    }
  },
})
</script>
<style scoped>
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
