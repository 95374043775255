const sortTypes = [
  // Sort contacts by first name
  {
    default: true,
    type: 'firstName_asc',
    title: 'Prénom (ordre alphabétique)',
    sort: (contactA, contactB) => {
      if (!contactA.fields || !contactA.fields.firstName) return -1
      if (!contactB.fields || !contactB.fields.firstName) return 1
      if (contactA.fields.firstName < contactB.fields.firstName) return -1
      if (contactA.fields.firstName > contactB.fields.firstName) return 1

      return 0
    },
  },

  // Sort contacts by last name
  {
    type: 'lastName_asc',
    title: 'Nom (ordre alphabétique)',
    sort: (contactA, contactB) => {
      if (!contactA.fields || !contactA.fields.lastName) return -1
      if (!contactB.fields || !contactB.fields.lastName) return 1
      if (contactA.fields.lastName < contactB.fields.lastName) return -1
      if (contactA.fields.lastName > contactB.fields.lastName) return 1

      return 0
    },
  },

  // Sort contacts by last updated date
  {
    type: 'updated_at_desc',
    title: 'Dernière mise à jour (plus récent)',
    sort: (contactA, contactB) => {
      if (!contactA.updated_at) return 1
      if (!contactB.updated_at) return -1
      if (contactA.updated_at.seconds < contactB.updated_at.seconds) return 1
      if (contactA.updated_at.seconds > contactB.updated_at.seconds) return -1

      return 0
    },
  },

  // Sort contacts by last updated date
  {
    type: 'updated_at_asc',
    title: 'Dernière mise à jour (plus ancien)',
    sort: (contactA, contactB) => {
      if (!contactA.updated_at) return -1
      if (!contactB.updated_at) return 1
      if (contactA.updated_at.seconds < contactB.updated_at.seconds) return -1
      if (contactA.updated_at.seconds > contactB.updated_at.seconds) return 1

      return 0
    },
  },

  // Sort contacts by creation date
  {
    type: 'created_at_desc',
    title: 'Date de création (plus récent)',
    sort: (contactA, contactB) => {
      if (!contactA.created_at) return 1
      if (!contactB.created_at) return -1
      if (contactA.created_at.seconds < contactB.created_at.seconds) return 1
      if (contactA.created_at.seconds > contactB.created_at.seconds) return -1

      return 0
    },
  },

  // Sort contacts by creation date
  {
    type: 'created_at_asc',
    title: 'Date de création (plus ancien)',
    sort: (contactA, contactB) => {
      if (!contactA.created_at) return -1
      if (!contactB.created_at) return 1
      if (contactA.created_at.seconds < contactB.created_at.seconds) return -1
      if (contactA.created_at.seconds > contactB.created_at.seconds) return 1

      return 0
    },
  },
]

const getSortByType = type => sortTypes.find(sortType => sortType.type === type)
const getSortByTypeOrDefault = type => getSortByType(type) || sortTypes.find(sort => sort.default) || sortTypes[0]

export { sortTypes, getSortByType, getSortByTypeOrDefault }
