var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['task', 'pa-0', {'elevation-3': !_vm.task.done}]},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isHovered = ref.hover;
return [_c('v-card',{staticClass:"task-card text-wrap py-0",class:{'done': _vm.task.done},attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"py-2 px-3"},[_c('div',{staticClass:"d-flex"},[_c('v-checkbox',{staticClass:"pa-0 align-center",attrs:{"hide-details":""},on:{"change":_vm.onChange},model:{value:(_vm.task.done),callback:function ($$v) {_vm.$set(_vm.task, "done", $$v)},expression:"task.done"}}),(_vm.task.done)?[_c('span',[_vm._v(_vm._s(_vm.shortenedTask))]),_c('v-chip',{staticClass:"ml-1",attrs:{"color":"primary","label":"","small":""}},[_vm._v(" Terminé ")])]:[_c('v-textarea',{staticClass:"pa-0 ma-0 task-text-input",attrs:{"placeholder":"Description de la tâche","hide-details":"","auto-grow":"","no-resize":"","flat":"","solo":"","rows":1},on:{"change":_vm.onChange},model:{value:(_vm.task.label),callback:function ($$v) {_vm.$set(_vm.task, "label", $$v)},expression:"task.label"}})],_c('v-menu',{attrs:{"close-on-content-click":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(isHovered || _vm.menu),expression:"isHovered || menu"}],staticClass:"py-0 my-0 ml-auto",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('div',[_c('v-list',{staticClass:"pa-0"},[(!_vm.task.done)?_c('v-list-item',{staticClass:"task-action-item"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" Modifier la date ")])]}}],null,true),model:{value:(_vm.menuChangeDate),callback:function ($$v) {_vm.menuChangeDate=$$v},expression:"menuChangeDate"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Modifier la date ")]),_c('v-date-picker',{attrs:{"color":"primary","no-title":"","locale":"fr-fr"},on:{"change":_vm.onChange},model:{value:(_vm.task.reminderDate),callback:function ($$v) {_vm.$set(_vm.task, "reminderDate", $$v)},expression:"task.reminderDate"}})],1)],1)],1):_vm._e(),_c('v-list-item',{staticClass:"task-action-item"},[_c('v-btn',{attrs:{"small":"","text":"","color":"error"},on:{"click":_vm.onDelete}},[_vm._v(" Supprimer ")])],1)],1)],1)])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.task.done),expression:"!task.done"}],staticClass:"d-flex py-0"},[_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayContact),expression:"displayContact"}],attrs:{"small":"","label":""}},[_c('ContactDisplayName',{attrs:{"display-avatar":false,"clickable":true,"contact":_vm.contact,"field-max":1}})],1),_c('span',{staticClass:"text-caption ml-auto"},[_vm._v("Prévu pour "+_vm._s(_vm.formattedDate))])],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }