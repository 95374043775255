<template>
  <div :class="['task', 'pa-0', {'elevation-3': !task.done}]">
    <v-hover

      #default="{ hover: isHovered }"
    >
      <v-card
        outlined
        :class="{'done': task.done}"
        class="task-card text-wrap py-0"
      >
        <v-card-text class="py-2 px-3">
          <div class="d-flex">
            <!-- Checkbox done / undone -->
            <v-checkbox
              v-model="task.done"
              hide-details
              class="pa-0 align-center"
              @change="onChange"
            />
            <!-- Task is done -->
            <template v-if="task.done">
              <span>{{ shortenedTask }}</span>
              <v-chip
                class="ml-1"
                color="primary"
                label
                small
              >
                Terminé
              </v-chip>
            </template>

            <!-- Task is not done -->
            <template v-else>
              <v-textarea
                v-model="task.label"
                class="pa-0 ma-0 task-text-input"
                placeholder="Description de la tâche"
                hide-details
                auto-grow
                no-resize
                flat
                solo
                :rows="1"
                @change="onChange"
              ></v-textarea>
            </template>

            <v-menu
              v-model="menu"
              :close-on-content-click="true"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-show="isHovered || menu"
                  class="py-0 my-0 ml-auto"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </template>
              <div>
                <v-list class="pa-0">
                  <v-list-item
                    v-if="!task.done"
                    class="task-action-item"
                  >
                    <v-menu
                      v-model="menuChangeDate"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          small
                          text
                          v-on="on"
                        >
                          Modifier la date
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          Modifier la date
                        </v-card-title>
                        <v-date-picker
                          v-model="task.reminderDate"
                          color="primary"
                          no-title
                          locale="fr-fr"
                          @change="onChange"
                        ></v-date-picker>
                      </v-card>
                    </v-menu>
                  </v-list-item>
                  <v-list-item class="task-action-item">
                    <v-btn
                      small
                      text
                      color="error"
                      @click="onDelete"
                    >
                      Supprimer
                    </v-btn>
                  </v-list-item>
                </v-list>
              </div>
            </v-menu>
          </div>
          <div
            v-show="!task.done"
            class="d-flex py-0"
          >
            <v-chip
              v-show="displayContact"
              small
              label
            >
              <ContactDisplayName
                :display-avatar="false"
                :clickable="true"
                :contact="contact"
                :field-max="1"
              />
            </v-chip>
            <span class="text-caption ml-auto">Prévu pour {{ formattedDate }}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-hover>
  </div>
</template>
<script>
import { mdiAccountBoxOutline, mdiClose, mdiDotsVertical } from '@mdi/js'
import { computed, defineComponent, ref } from '@vue/composition-api'
import moment from 'moment'
import ContactDisplayName from '@/components/ContactDisplayName.vue'
import store from '@/store'
import humanReadableDateFromNow from '@/utils/date'

export default defineComponent({
  components: { ContactDisplayName },
  name: 'Task',
  props: {
    value: {
      type: Object,
      required: true,
    },
    displayContact: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const task = ref(props.value)
    const relativeReminderDate = reminderDate => moment(reminderDate, 'YYYY-MM-DD').fromNow()
    const menu = ref(false)
    const menuChangeDate = ref(false)
    const onChange = () => {
      menu.value = false
      emit('change', task.value)
    }
    const onDelete = () => {
      emit('delete', task.value)
    }
    const contact = computed(() => store.getters['contacts/getContactById'](task.value.contactId))
    const formattedDate = computed(() => humanReadableDateFromNow(task.value.reminderDate))
    const shortenedTask = computed(() => {
      const { label } = task.value
      if (label.length > 15) {
        return `${label.slice(0, 15)}...`
      }

      return label
    })

    return {
      relativeReminderDate,
      onChange,
      onDelete,
      contact,
      task,
      menu,
      menuChangeDate,
      formattedDate,
      shortenedTask,
      icons: { mdiClose, mdiAccountBoxOutline, mdiDotsVertical },
    }
  },
})
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins';

.task-action-item {
  padding: 0;
  height: 1rem !important;
}
.v-btn {
  text-transform: none;
}
.task {
  border-radius: 3px;
  border: 1px solid rgb(221, 221, 221);
  padding: 0;
}
.task-card {
  border: none !important;
}
.task-title {
  font-size: 12px;
}
.done {
  // Selector for the task card
}
@include theme--child(done) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), 0.08);
}
</style>
