import Vue from 'vue'
import VueRouter from 'vue-router'

// Store
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'contacts',
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/Contacts.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/signup',
    name: 'auth-signup',
    component: () => import('@/views/Signup.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/ResetPasswordRequest.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (!to.meta?.authRequired || store.state.user.authorized) {
    next()
  } else {
    next({
      name: 'auth-login',
      query: {
        redirect: to.fullPath,
      },
    })
  }
})

export default router
