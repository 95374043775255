<template>
  <div>
    Version d'essai
    <span v-if="leftDays > 0">
      {{ leftDays }}
      jour(s) restant(s)
    </span>
    <span v-else>terminée. <b>Contactez le support.</b></span>
    <v-progress-linear
      :value="percentage"
      :color="calculateColor"
    ></v-progress-linear>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import moment from 'moment'

export default {
  props: {
    trialEnd: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const leftDays = computed(() => {
      console.log(props.trialEnd)
      const endTrialDate = moment(props.trialEnd)
      const now = moment()

      return endTrialDate.diff(now, 'days')
    })
    const percentage = computed(() => {
      const endTrialDate = moment(props.trialEnd)
      const now = moment()

      return (endTrialDate.diff(now, 'days') / 15) * 100
    })
    const calculateColor = computed(() => {
      if (leftDays.value <= 0) {
        return 'error'
      }
      if (leftDays.value < 10) {
        return 'warning'
      }

      return 'success'
    })

    return {
      leftDays,
      calculateColor,
      percentage,
    }
  },
}
</script>

<style>
</style>
