// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { getPerformance } from 'firebase/performance'

import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'

// Firestore
import { connectFirestoreEmulator } from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAki_gNtvNKJmSeKMhLNYeRpvCNi7MPqTI',
  authDomain: 'incarnet-dev.firebaseapp.com',
  projectId: 'incarnet-dev',
  storageBucket: 'incarnet-dev.appspot.com',
  messagingSenderId: '354897687710',
  appId: '1:354897687710:web:332b4d7ca6a122585c378f',
  measurementId: 'G-PFERTCY63D',
}

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)
const db = app.firestore()
export const auth = getAuth()

// Initialize Performance Monitoring and get a reference to the service
getPerformance(app)

console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === 'development') {
  console.log('Using Firebase emulators')
  connectFirestoreEmulator(db, 'localhost', 8181)
  connectAuthEmulator(auth, 'http://localhost:9099')
}

// signup with email and password
export const createUser = (email, password) => createUserWithEmailAndPassword(auth, email, password)

// signin with email and password
export const login = (email, password) => signInWithEmailAndPassword(auth, email, password)

// reset password with email
export const resetPasswordRequest = email => sendPasswordResetEmail(auth, email)

// signout
export const logout = () => signOut(auth)
  .then(() => true)
  .catch(error => {
    const errorCode = error.code
    const errorMessage = error.message
    console.error(errorMessage, errorCode)

    return false
  })
