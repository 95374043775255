import _ from 'lodash'

const baseFields = {
  firstName: {
    id: 'firstName',
    name: 'Prénom',
    type: 'text',
    deletable: false,
    required: true,
    orderKey: 1,
    personalInfo: true,
  },
  lastName: {
    id: 'lastName',
    name: 'Nom',
    type: 'text',
    deletable: false,
    required: true,
    orderKey: 2,
    personalInfo: true,
  },
  email: {
    id: 'email',
    name: 'Email',
    type: 'email',
    deletable: true,
    required: false,
    orderKey: 3,
    personalInfo: true,
  },
  phone: {
    id: 'phone',
    name: 'Téléphone',
    type: 'phone',
    deletable: true,
    required: false,
    orderKey: 4,
    personalInfo: true,
  },
  company: {
    id: 'company',
    name: 'Entreprise',
    type: 'company',
    deletable: true,
    required: false,
    orderKey: 5,
  },
}

const defaultModelName = 'Nouveau Carnet'

const models = {
  firestorePath: 'data/{userId}/models',
  firestoreRefType: 'collection',
  moduleName: 'models',
  statePropName: 'models',
  namespaced: true,
  state: {},
  getters: {
    getModels: state => {
      const values = _.values(JSON.parse(JSON.stringify(state.models)))
      _.remove(values, model => model.id === 'selectedModel')

      return values
    },
    getModelById: state => id => state.models[id],
    getSelectedModel: state => {
      const allModels = _.values(JSON.parse(JSON.stringify(state.models)))
      const selectedModelId = _.chain(allModels)
        .filter(model => model.id === 'selectedModel')
        .map('selectedModelId')
        .last()
        .value()

      return state.models[selectedModelId]
    },
    getAllCurrentModelFields: (state, getters) => {
      const allModels = _.values(JSON.parse(JSON.stringify(state.models)))
      const selectedModelId = _.chain(allModels)
        .filter(model => model.id === 'selectedModel')
        .map('selectedModelId')
        .last()
        .value()
      if (selectedModelId == null) return []
      const selectedModel = getters.getModelById(selectedModelId)
      const currentFields = _.values(selectedModel.fields)

      return currentFields
    },
    getFieldTypeById: (state, getters) => id => {
      if (id) {
        const selectedModel = getters.getSelectedModel
        const field = _.find(selectedModel.fields, { id })

        return field.type
      }

      return null
    },
    nextOrderKeyField: (state, getters) => {
      const currentFields = getters.getAllCurrentModelFields
      const lastField = _.chain(currentFields).sortBy('orderKey').last().value()

      return lastField ? lastField.orderKey + 1 : 1
    },
    getFieldsSuggestionsForFiltering: (state, getters) => {
      const currentFields = getters.getAllCurrentModelFields

      return _.chain(currentFields)
        .filter(field => !field.personalInfo)
        .orderBy('orderKey')
        .slice(0, 3)
        .value()
    },
  },
  mutations: {},
  actions: {
    CREATE_MODEL: ({ dispatch }) => dispatch('insert', { modelName: defaultModelName, fields: baseFields }).then(id => {
      // Create initial selectedModel document
      const newDoc = { id: 'selectedModel', selectedModelId: id }
      dispatch('insert', newDoc)
    }),
    SET_SELECTED_MODEL: ({ dispatch }, selectedModelId) => {
      dispatch('patch', {
        id: 'selectedModel',
        selectedModelId,
      })
    },
  },
}

export default models
