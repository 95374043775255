import _ from 'lodash'
import { sortTypes } from '@/sortTypes'

const sorts = {
  firestorePath: 'data/{userId}/sorts',
  firestoreRefType: 'collection',
  moduleName: 'sorts',
  statePropName: 'sorts',
  namespaced: true,
  state: {},
  getters: {
    getSortsByModelId: state => modelId => {
      const activeSorts = _.filter(_.values(structuredClone(state.sorts)), s => s.modelId === modelId)

      return sortTypes.map(sortType => {
        const activeSort = activeSorts.find(sort => sort.type === sortType.type)
        const active = activeSort || (sortType.default && activeSorts.length === 0)
        const id = activeSort ? activeSort.id : null

        return {
          active,
          sortFunction: sortType.sort,
          id,
          ...sortType,
        }
      })
    },
  },
  mutations: {},
  actions: {
    ADD_SORT: ({ dispatch }, { modelId, type }) => dispatch('insert', {
      modelId,
      type,
    }).then(id => id),
    DELETE_SORT: ({ dispatch }, { id }) => dispatch('delete', id),
    UPDATE_SORT: ({ dispatch }, { id, type }) => {
      dispatch('patch', { id, type })
    },
  },
}

export default sorts
