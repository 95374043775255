<template>
  <div class="d-flex">
    <TrialSubscriptionBadge
      v-if="endTrialDate"
      :trial-end="endTrialDate"
      class="mr-2"
    />
    <v-menu
      v-model="tasksMenu"
      :close-on-content-click="false"
      origin="bottom right"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          color="primary"
          :content="taskPendingCount"
          :value="taskPendingCount"
          :offset-x="10"
          :offset-y="10"
        >
          <v-badge
            color="error"
            :content="tasksLateCount"
            :value="tasksLateCount"
            :offset-y="10"
            :offset-x="130"
          >
            <v-btn
              class="task-btn"
              small
              secondary
              outlined
              v-bind="attrs"
              v-on="on"
            >
              Voir les tâches
            </v-btn>
          </v-badge>
        </v-badge>
      </template>
      <TaskList
        class="menu-task"
        :default-interval="tasksLateCount ? 'late' : 'today'"
      />
    </v-menu>
  </div>
</template>

<script>
import { mdiBellOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import TaskList from '@/components/TaskList.vue'
import TrialSubscriptionBadge from '@/components/TrialSubscriptionBadge.vue'
import store from '@/store'
import filterTasksByInterval from '@/utils/tasks'

export default {
  components: { TaskList, TrialSubscriptionBadge },
  setup(props) {
    const tasksMenu = ref(false)
    const endTrialDate = computed(() => store.getters['user/trialEnd'])
    const tasks = computed(() => {
      if (props.contactId) {
        return store.getters['contacts/getTasksByContactId'](props.contactId)
      }

      return store.getters['contacts/getAllTasks']
    })
    const taskPendingCount = computed(
      () => filterTasksByInterval(tasks.value, 'today').filter(task => !task.done).length,
    )
    const tasksLateCount = computed(() => filterTasksByInterval(tasks.value, 'late').filter(task => !task.done).length)

    return {
      taskPendingCount,
      tasksLateCount,
      tasksMenu,
      endTrialDate,
      icons: {
        mdiBellOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.menu-task {
  width: 350px !important;
  padding: 10px;
}
.task-btn {
  text-transform: none;
}
</style>
