import * as firebase from '@/firebase'

const defaultState = {
  uid: '',
  displayName: '',
  email: '',
  photoURL: '',
  authorized: false,
  error: null,
}

export default {
  firestorePath: 'data/{userId}',
  firestoreRefType: 'document',
  moduleName: 'user',
  statePropName: 'user',
  namespaced: true,
  state: { ...defaultState },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    RESET_STATE(state) {
      Object.assign(state, defaultState)
    },
  },
  actions: {
    WATCH_USER({ commit, dispatch }) {
      firebase.auth.onAuthStateChanged(user => {
        if (user) {
          // User is signed in.
          commit('SET_STATE', {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            authorized: true,
          })
          dispatch('initializeDBChannels', null, { root: true })
        } else {
          // User is signed out.
          commit('SET_STATE', defaultState)
          dispatch('closeDBChannels', null, { root: true })
        }
      })
    },

    // Login with email and password
    LOGIN({ commit }, payload) {
      const { email, password } = payload
      commit('SET_STATE', { error: null })

      return new Promise((resolve, reject) => {
        firebase
          .login(email, password)
          .then(success => {
            resolve(success)
          })
          .catch(error => {
            commit('RESET_STATE')
            reject(error)
          })
      })
    },

    // Signup with email and password
    SIGNUP({ commit, dispatch }, payload) {
      const { email, password } = payload

      return new Promise((resolve, reject) => {
        firebase
          .createUser(email, password)
          .then(user => {
            dispatch('LOGIN', { email, password })
            dispatch('models/CREATE_MODEL', null, { root: true })
            resolve(user)
          })
          .catch(e => {
            commit('RESET_STATE')
            reject(e)
          })
      })
    },

    // Reset password request
    RESET_PASSWORD_REQUEST(context, payload) {
      const { email } = payload

      return firebase.resetPasswordRequest(email)
    },

    // Logout
    LOGOUT() {
      firebase.logout()
    },
  },
  getters: {
    user: state => state,
    error: state => state.error,
    trialEnd: state => state.user.trialEnd?.seconds * 1000,
  },
}
