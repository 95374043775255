const moment = require('moment')

const humanReadableDateFromNow = date => {
  const selectedDate = moment(date)
  const diffDays = selectedDate.diff(moment().startOf('days'), 'days')
  switch (diffDays) {
    case -1:
      return 'hier'
    case 0:
      return "aujourd'hui"
    case 1:
      return 'demain'
    case 2:
      return 'après-demain'
    default:
      return `le ${selectedDate.format('dddd DD MMMM')}`
  }
}

export default humanReadableDateFromNow
